import { useState } from 'react';
import classNames from 'classnames';
import styles from './LanguageSelector.module.scss';
import { Icon } from 'ui/components/1-atoms/Media/Icon/Icon';
import ChevronUp from 'ui/icons/icon-chevron-right-small.svg';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';
import { useRouteContext } from 'ui/contexts/RouteContext';

export interface LanguageSelectorProps {
	className?: string;
	languages: {
		name: string;
		locale: string;
		path: string;
	}[];
	currentLanguage: {
		name: string;
		locale: string;
		path: string;
	};
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({ className, languages, currentLanguage }) => {
	const routeHandler = useRouteContext();
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const toggleOpen = () => {
		setIsOpen(!isOpen);
	};

	const onEnterEvent = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			toggleOpen();
		}
	};

	const findLocale = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const chosenLanguage: {
			name: string;
			locale: string;
			path: string;
		} = languages.find((language) => e.target.value === language.locale);
		return chosenLanguage.locale;
	};

	return (
		languages.length > 1 && (
			<div className={classNames(styles.LanguageSelector, className)}>
				{/* DESKTOP */}
				<div className={styles.LanguageSelector_list}>
					<div
						tabIndex={0}
						className={classNames(styles.LanguageSelector_toggle, {
							[styles.isOpen]: isOpen,
						})}
						aria-expanded={isOpen}
						onClick={toggleOpen}
						onKeyDown={onEnterEvent}
					>
						<p>{currentLanguage.name}</p>
						<div className={styles.LanguageSelector_icon}>
							<Icon size="md">
								<ChevronUp />
							</Icon>
						</div>
					</div>

					<ul role="navigation" className={classNames(styles.LanguageSelector_dropdown, { [styles.isOpen]: isOpen })}>
						{languages.map((language, index) => {
							return (
								language.locale !== currentLanguage.locale && (
									<li key={index} onClick={toggleOpen}>
										<Link
											className={classNames(styles.LanguageSelector_item, styles.LanguageSelector_link)}
											href={'/'}
											locale={language.locale}
										>
											{language.name}
										</Link>
									</li>
								)
							);
						})}
					</ul>
				</div>

				{/* MOBILE */}
				<select role="navigation" className={styles.LanguageSelector_select} onChange={(e) => routeHandler(e, '/', findLocale(e))}>
					<option value={currentLanguage.locale} hidden>
						{currentLanguage.name}
					</option>
					{languages.map((language, index) => {
						return (
							language.locale !== currentLanguage.locale && (
								<option key={index} value={language.locale}>
									{language.name}
								</option>
							)
						);
					})}
				</select>
			</div>
		)
	);
};
