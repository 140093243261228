import { createContext, useState } from 'react';

interface InViewContextProviderProps {
	children: React.ReactNode;
}

export const InViewContext = createContext(null);

export const InViewContextProvider: React.FC<InViewContextProviderProps> = ({ children }) => {
	const [isHeroInView, setisHeroInView] = useState(false);

	const toggleisHeroInView = (isInView) => {
		setisHeroInView(isInView);
	};

	return <InViewContext.Provider value={{ isHeroInView, toggleisHeroInView }}>{children}</InViewContext.Provider>;
};
