import logger from 'helpers/logger';
import Script from 'next/script';

export interface GoogleTagManagerProps {
	containerId?: string;
	containerDomain?: string;
}

export const GoogleTagManager: React.FC<GoogleTagManagerProps> = ({ containerId, containerDomain = 'https://www.googletagmanager.com' }) => {
	if (!containerId) {
		logger.warn('[GoogleTagManager] Invalid configuration. No container Id specified.');
		return null;
	}

	let containerDomainURL: URL;
	try {
		containerDomainURL = new URL(containerDomain);
	} catch {
		logger.warn('[GoogleTagManager] Invalid configuration. No valid domain specified.');
		return null;
	}

	const gtmHead = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(), event: 'gtm.js'
}); var f = d.getElementsByTagName(s)[0],
	j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
	'${containerDomainURL?.origin ?? 'https://www.googletagmanager.com'}/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
}) (window, document, 'script', 'dataLayer', '${containerId}');`;

	return (
		<>
			{/*<!-- Google Tag Manager --> */}
			<Script strategy="beforeInteractive" id="gtm">
				{gtmHead}
			</Script>
			{/*<!-- End Google Tag Manager --> */}

			{/* <!-- Google Tag Manager (noscript) --> */}
			<noscript>
				<iframe
					src={`${containerDomainURL?.origin ?? 'https://www.googletagmanager.com'}/ns.html?id=${containerId}`}
					height="0"
					width="0"
					style={{ display: 'none', visibility: 'hidden' }}
				></iframe>
			</noscript>
			{/* <!-- End Google Tag Manager (noscript) --> */}
		</>
	);
};
