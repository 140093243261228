import React from 'react';
import classNames from 'classnames';
import styles from './Heading.module.scss';

export interface HeadingProps {
	children?: React.ReactNode;
	className?: string;
	headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	style?: 'default' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'reg' | 'xxl';
	color?: string;
}

export const Heading: React.FC<HeadingProps> = ({ children, className, headingLevel, style = 'default', color }) =>
	React.createElement(
		headingLevel,
		{ className: classNames(styles.Heading, styles[`Heading___${style}`], className), style: { color: color } },
		children,
	);
