import classNames from 'classnames';

export interface GridCellProps {
	className?: string;
	children: React.ReactNode;
	center?: boolean;
	mobileWidth?: '10' | '25' | '33' | '50' | '66' | '75' | '100' | '';
	desktopWidth?: '5' | '10' | '25' | '33' | '50' | '66' | '75' | '100' | '';
}

export const GridCell: React.FC<GridCellProps> = ({ className, mobileWidth = '100', desktopWidth = '100', children, center }) => {
	return (
		<div
			className={classNames(
				'u-grid__cell',
				'u-grid__cell--width-100',
				{ [`u-grid__cell--width-${mobileWidth}@xs`]: mobileWidth },
				{ [`u-grid__cell--width-${desktopWidth}@md`]: desktopWidth },
				{ 'u-grid__cell--center': center },
				className,
			)}
		>
			{children}
		</div>
	);
};
