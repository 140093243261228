import classNames from 'classnames';
import styles from './Grid.module.scss';

export interface GridProps {
	className?: string;
	wrap?: boolean;
	container?: boolean;
	nogutter?: boolean;
	largeGutter?: boolean;
	center?: boolean;
	horizontalCenter?: boolean;
	full?: boolean;
	children?: React.ReactNode;
	spaceBetween?: boolean;
}

export const Grid: React.FC<GridProps> = ({
	wrap,
	container = true,
	nogutter,
	largeGutter,
	center,
	horizontalCenter,
	full,
	children,
	className,
	spaceBetween,
}) => (
	<div
		className={classNames(
			styles.Grid,
			{ [styles.Grid___wrap]: wrap },
			{ [styles.Grid___container]: container },
			{ [styles.Grid___noGutter]: nogutter },
			{ [styles.Grid___largeGutter]: largeGutter },
			{ [styles.Grid___center]: center },
			{ [styles.Grid___full]: full },
			{ [styles.Grid___horizontalCenter]: horizontalCenter },
			{ [styles.Grid___spaceBetween]: spaceBetween },
			className,
		)}
	>
		{children}
	</div>
);
