import { DectectKeyboard } from './DetectKeyboard/DetectKeyboard';
import { RouteContextProvider } from 'ui/contexts/RouteContext';
import { InViewContextProvider } from 'ui/contexts/InViewContext';

type LayoutProps = {
	children?: React.ReactNode;
	router;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Layout: React.FC<LayoutProps> = ({ children, router }) => {
	return (
		<RouteContextProvider router={router}>
			<DectectKeyboard />
			<InViewContextProvider>{children}</InViewContextProvider>
		</RouteContextProvider>
	);
};
