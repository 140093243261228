import logger from 'helpers/logger';
import { capitalizeFirstLetter } from 'helpers/string';
import { NODE_ENV } from '../../_constants';
import dynamic from 'next/dynamic';

// Dynamic import can't be used inside of React rendering as it needs to be marked in the top level of the module for preloading to work, similar to React.lazy.
// https://nextjs.org/docs/pages/building-your-application/optimizing/lazy-loading#examples

// Don't get tempted to dynamically generate the imports, eg. with a forEach loop and template literals or similar.
// Tried and tested, it just wont work. It fails silently by disabling SSR and initial hydration of data!
// From the documentation: In import('path/to/component'), the path must be explicitly written. It can't be a template string nor a variable.

// Add a new page type by adding it to the imports
const features = {
	ArticlePageFeature: dynamic(() => import('../../PageTypes/ArticlePage/ArticlePageFeature').then((module) => module['ArticlePageFeature'])),
	ContentPageFeature: dynamic(() => import('../../PageTypes/ContentPage/ContentPageFeature').then((module) => module['ContentPageFeature'])),
	FilteredLibraryPageFeature: dynamic(() =>
		import('../../PageTypes/FilteredLibraryPage/FilteredLibraryPageFeature').then((module) => module['FilteredLibraryPageFeature']),
	),
	HomePageFeature: dynamic(() => import('../../PageTypes/HomePage/HomePageFeature').then((module) => module['HomePageFeature'])),
	NewsPageFeature: dynamic(() => import('../../PageTypes/NewsPage/NewsPageFeature').then((module) => module['NewsPageFeature'])),
	NewsPageWithTagsPageFeature: dynamic(() =>
		import('../../PageTypes/NewsPageWithTags/NewsPageWithTagsFeature').then((module) => module['NewsPageWithTagsFeature']),
	),
};

export const PageTypeFeature: React.FC<Content.Page> = (page) => {
	const production = NODE_ENV === 'production';
	const featureName = `${capitalizeFirstLetter(page.template)}Page`;
	const pageFeatureName = `${featureName}Feature`;

	const PageTypeTemplate = features[pageFeatureName];

	if (!PageTypeTemplate) {
		const message = `PageType [${page.template}] is missing a React Component. Add missing export '${featureName}' from /features`;
		logger.error(message);
		if (!production) {
			return <div>{message}</div>;
		} else {
			return null;
		}
	}

	return <PageTypeTemplate {...page} />;
};
