import classNames from 'classnames';
import styles from './Header.module.scss';
import { SkipToMain } from 'ui/components/1-atoms/SkipToMain/SkipToMain';
import { Container } from 'ui/components/4-habitats/Container/Container';
import { Grid } from 'ui/components/4-habitats/Grid/Grid';
import { GridCell } from 'ui/components/4-habitats/Grid/GridCell';
import { KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock/lib/bodyScrollLock';
import { throttle } from 'helpers/throttle';
export interface HeaderProps {
	className?: string;
	logoArea?: React.ReactNode;
	cartArea?: React.ReactNode;
	children?: React.ReactNode;
	languageSelector?: React.ReactNode;
	toggled?: boolean;
	lightHeader?: boolean;
	toggleMenu?: () => void;
	menuLabel?: string;
	closeMenuLabel?: string;
	skipToContentLabel?: string;
	skipToContentTitle?: string;
	closeMenuRef?: React.Ref<HTMLLabelElement>;
	isTransitioning?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
	className,
	children,
	logoArea,
	toggled = false,
	lightHeader,
	toggleMenu,
	menuLabel,
	closeMenuLabel,
	skipToContentLabel,
	skipToContentTitle,
	closeMenuRef,
	isTransitioning = true,
}) => {
	const [headerElementColor, setHeaderElementColor] = useState<boolean>(lightHeader);
	const [isTransparent, setIsTransparent] = useState(true);
	const [isActive, setIsActive] = useState(true);
	const headerRef = useRef<HTMLDivElement>(null);
	let headerElement: HTMLDivElement;

	const handleKeyDown: KeyboardEventHandler<HTMLLabelElement> = (event) => {
		if (event.key === 'Enter') {
			toggleMenu();
		}
	};

	useEffect(() => {
		// const headerHeight = headerRef.current.clientHeight.toString();
		let prevScrollPos = window.scrollY;

		const onScroll = throttle(() => {
			const currentScrollPos = window.scrollY;

			// Toggle header color on scroll
			if (prevScrollPos < 500) {
				setIsTransparent(true);
				setHeaderElementColor(lightHeader);
			} else if (prevScrollPos >= 500) {
				setIsTransparent(false);
				setHeaderElementColor(false);
			}
			// Fade in/Out sticky navbar on scroll
			// if topmost position, always show header
			if (prevScrollPos < 0 || prevScrollPos > currentScrollPos) {
				// headerRef.current.style.top = '0';

				setIsActive(true);
			} else {
				// headerRef.current.style.top = `-${headerHeight}px`;

				setIsActive(false);
			}
			prevScrollPos = currentScrollPos;
		}, 200);

		document.addEventListener('scroll', onScroll);

		// Transparent header on pageload
		if (prevScrollPos < 500) {
			setIsTransparent(true);
		}
		headerElement = headerRef.current;

		if (headerElement && toggled) {
			disableBodyScroll(headerElement, {});
		} else {
			enableBodyScroll(headerElement);
		}

		return () => {
			clearAllBodyScrollLocks();
		};
	}, [lightHeader]); // remove toggled from deps if it's not supposed to be hidden after toggled closes
	// we probably need to remove the scroll event listener on the return function too

	return (
		<header
			ref={headerRef}
			className={classNames(
				styles.Header,
				{ [styles.Header___transparent]: isTransparent },
				{ [styles.Header___toggled]: toggled },
				{ [styles.Header___light]: headerElementColor },
				{ [styles.Header___hidden]: !isActive && !toggled && !isTransitioning },
				className,
			)}
			role="banner"
			aria-expanded={toggled}
		>
			<SkipToMain link="#main" title={skipToContentTitle}>
				{skipToContentLabel}
			</SkipToMain>
			<Container width="Standard">
				<Grid container wrap={true} center={true}>
					<GridCell className={styles.Header_identity} mobileWidth="50" desktopWidth="50">
						{logoArea}
					</GridCell>
					{children && (
						<GridCell className={styles.Header_close} mobileWidth="50" desktopWidth="50">
							<input className={styles.Header_navController} type="checkbox" id="nav-controller" data-ref="header__navController" />

							<label
								ref={closeMenuRef}
								className={styles.Header_navToggle}
								htmlFor="nav-controller"
								onClick={toggleMenu}
								onKeyDown={handleKeyDown}
								tabIndex={0}
							>
								{!toggled ? menuLabel : closeMenuLabel}
							</label>
						</GridCell>
					)}
					<GridCell className={styles.Header_navContainer} mobileWidth="100" desktopWidth="100">
						<div className={classNames(styles.Header_navigation)}>
							{children && toggled && (
								<>
									<div className={styles.Header_nav} data-ref="header__navPanel" onClick={toggleMenu}>
										<Grid container wrap={true}>
											<GridCell>{children}</GridCell>
										</Grid>
									</div>
								</>
							)}
						</div>
					</GridCell>
				</Grid>
			</Container>
		</header>
	);
};
