import Head from 'next/head';
import { CookieConsent } from './CookieConsent';
import { OpenGraph } from './OpenGraph';
import { GoogleTagManager } from './GoogleTagManager';
import { useSiteContext } from 'application/contexts/SiteContext';
import { usePageContext } from 'application/contexts/PageContext';
import { GLOBAL_THEME_DEFAULT } from '_constants';

export const Meta: React.FC<Content.PageContent['page']['properties']> = ({ title, noFollow, noIndex, description }) => {
	const siteContext = useSiteContext();
	const pageContext = usePageContext();

	const openGraph = pageContext?.getSettings('openGraph');
	const tagManager = siteContext?.settings?.tagManager;
	const siteTitle = siteContext?.title;
	const globalTheme = siteContext?.settings?.globalTheme?.setTheme ?? GLOBAL_THEME_DEFAULT;
	const imageRoot = globalTheme === 'kogp' ? 'kogp' : '';

	return (
		<>
			<Head>
				<title>
					{title ? title : pageContext.name} {siteTitle ? ' - ' + siteTitle : ''}
				</title>
				<link rel="apple-touch-icon" sizes="180x180" href={imageRoot + `/apple-touch-icon.png`} />
				<link rel="icon" type="image/png" sizes="32x32" href={imageRoot + `/favicon-32x32.png`} />
				<link rel="icon" type="image/png" sizes="16x16" href={imageRoot + `/favicon-16x16.png`} />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
				<meta name="msapplication-TileColor" content="#da532c" />
				<meta name="theme-color" content="#ffffff" />
				<meta name="robots" content={`${noFollow ? 'nofollow' : 'follow'}, ${noIndex ? 'noindex' : 'index'}`} />
				<meta name="description" content={description} />
			</Head>
			{globalTheme === 'kogp' && <CookieConsent />}
			{openGraph && <OpenGraph {...openGraph} pageName={pageContext.name} pageUrl={pageContext.path} />}
			{tagManager && <GoogleTagManager {...tagManager} />}
		</>
	);
};
