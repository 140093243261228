export function throttle(func, wait) {
	let waiting = false;
	const returnFunc = function () {
		if (waiting) {
			return;
		}

		waiting = true;
		const toCb = () => {
			func.apply(this);
			waiting = false;
		};
		setTimeout(toCb, wait);
	};
	return returnFunc;
}
