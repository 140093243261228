import { get } from 'env-var';
import logger from 'helpers/logger';

let NODE_ENV: string;
let NEXT_PUBLIC_SITENAME: string;

// Read variables using env-var API
if (typeof window === 'undefined') {
	// Only perform assignment and check serverside / at build time
	NODE_ENV = get('NODE_ENV').required().asString();
	NEXT_PUBLIC_SITENAME = get('NEXT_PUBLIC_SITENAME').required().asString();
} else {
	try {
		// Force NextJs to bake in public vars client side using direct reference to process.env
		// https://github.com/evanshortiss/env-var/issues/162
		NEXT_PUBLIC_SITENAME = process.env.NEXT_PUBLIC_SITENAME;

		if (typeof NEXT_PUBLIC_SITENAME === 'undefined') {
			logger.error('Reference error - NEXT_PUBLIC_SITENAME is undefined');
			throw new Error(`NEXT_PUBLIC_SITENAME is undefined`);
		}
	} catch (e) {
		logger.error(e);
	}
}

export { NODE_ENV, NEXT_PUBLIC_SITENAME };
