import classNames from 'classnames';
import styles from './Navigation.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';

export interface NavigationProps {
	className?: string;
	style?: 'primary';
	heading?: string;
	navigationItems?: Array<NavigationItemProps>;
}

export interface NavigationItemProps {
	id: number;
	name: string;
	url: string;
	descendants?: Array<NavigationItemProps>;
	current?: boolean;
	active?: boolean;
	hidden?: boolean;
	title?: string;
}

export const Navigation: React.FC<NavigationProps> = ({ className, style, heading, navigationItems }) => (
	<nav className={classNames(styles.Navigation, styles[`Navigation___${style}`], className)} role="navigation">
		{heading && (
			<Heading headingLevel="h6" className={styles.Navigation_heading}>
				{heading}
			</Heading>
		)}
		<ul className={styles.Navigation_list}>
			{navigationItems &&
				navigationItems.map((navigationItem: NavigationItemProps, index) => (
					<li key={index} className={classNames(styles.Navigation_item)}>
						<Link
							href={navigationItem.url}
							style="xl"
							className={classNames(styles.Navigation_link, {
								[styles.isActive]: navigationItem.active === true,
								[styles.isCurrent]: navigationItem.current === true,
							})}
						>
							{navigationItem.title || navigationItem.name}
						</Link>
					</li>
				))}
		</ul>
	</nav>
);
