import React, { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DectectKeyboard = () => {
	useEffect(() => {
		document.body.addEventListener('keydown', () => {
			document.body.className = 'u-is-using-keyboard';
		});
		document.body.addEventListener('click', () => {
			document.body.className = '';
		});
		document.body.addEventListener('touchstart', () => {
			document.body.className = '';
		});
	}, []);

	return <></>;
};
