import { useSiteContext } from 'application/contexts/SiteContext';
import Head from 'next/head';

export const CookieConsent: React.FC = () => {
	const siteContext = useSiteContext();
	const lang = siteContext.culture.split('-')[0];

	return (
		<Head>
			<script
				async
				id="CookieConsent"
				src="https://policy.app.cookieinformation.com/uc.js"
				data-culture={lang}
				data-gcm-version="2.0"
				type="text/javascript"
			></script>
		</Head>
	);
};
