import classNames from 'classnames';

import styles from './Container.module.scss';

export type ContainerWidth = 'Small' | 'Standard' | 'Full width' | 'Skinny' | 'Slim' | '';

export interface ContainerProps {
	width: ContainerWidth;
	children: React.ReactNode;
	fillWidth?: boolean;
}

export const Container: React.FC<ContainerProps> = ({ width = 'Standard', children, fillWidth = true }) => {
	width = width === '' ? 'Standard' : width;

	return (
		<div
			className={classNames(
				styles.Container,
				{ [styles.Container___standard]: width === 'Standard' },
				{ [styles.Container___narrow]: width === 'Small' },
				{ [styles.Container___slim]: width === 'Slim' },
				{ [styles.Container___skinny]: width === 'Skinny' },
				{ [styles.Container___fillWidth]: fillWidth == true },
			)}
		>
			{children}
		</div>
	);
};
