import classNames from 'classnames';
import styles from './ServiceNavigation.module.scss';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';

export interface ServiceNavigationProps {
	className?: string;
	serviceNavigationItems?: Array<ServiceNavigationItemProps>;
	lastItemRef?: React.Ref<HTMLAnchorElement>;
}

export interface ServiceNavigationItemProps {
	id: number;
	name: string;
	url: string;
	descendants?: Array<ServiceNavigationItemProps>;
	current?: boolean;
	active?: boolean;
	hidden?: boolean;
	title?: string;
}

export const ServiceNavigation: React.FC<ServiceNavigationProps> = ({ className, serviceNavigationItems, lastItemRef }) => {
	if (!serviceNavigationItems) return null;
	return (
		<ul className={classNames(styles.ServiceNavigation, className)}>
			{serviceNavigationItems.map((item: ServiceNavigationItemProps, index) => (
				<li key={index} className={styles.ServiceNavigation_item}>
					<Link
						className={styles.ServiceNavigation_link}
						href={item.url}
						{...item}
						style="md"
						ref={index === serviceNavigationItems.length - 1 ? lastItemRef : undefined}
					>
						{item.title || item.name}
					</Link>
				</li>
			))}
		</ul>
	);
};
