import { Meta } from './Meta/Meta';
import { HeaderFeature } from '../Header/HeaderFeature';
import { Layout } from 'ui/components/4-habitats/Layout/Layout';
import { useRouter } from 'next/router';
import { PageProps } from 'domain/page/pageProps';
import { PropsWithChildren, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export const LayoutFeature: React.FC<PageProps & PropsWithChildren> = ({ children, content }) => {
	const { page, siteTheme = [] } = content;
	const { primaryColor, secondaryColor, lightHeader } = siteTheme[0]?.content?.properties?.theme?.properties ?? {};
	const router = useRouter();
	const [isTransitioning, setIsTransitioning] = useState(false);
	useEffect(() => {
		setIsTransitioning(true);

		const timeoutId = setTimeout(() => setIsTransitioning(false), 300);

		return () => clearTimeout(timeoutId);
	}, [router.asPath]);

	const themeStyle =
		primaryColor &&
		secondaryColor &&
		({
			'--color-primary': primaryColor,
			'--color-secondary': secondaryColor,
		} as React.CSSProperties);

	return (
		<div style={themeStyle}>
			<Layout router={router}>
				<AnimatePresence initial={false}>
					<motion.div
						key={router.asPath}
						initial="initialState"
						animate="animateState"
						exit="exitState"
						transition={{
							type: 'tween',
							duration: 0.3,
						}}
						variants={{
							initialState: {
								opacity: 0,
								y: '25vh',
							},
							animateState: {
								opacity: 1,
								y: 0,
							},
							exitState: {
								opacity: 0,
								y: '-25vh',
							},
						}}
						className="page-transition"
					>
						<Meta {...page?.properties} />
						<HeaderFeature lightHeader={lightHeader} isTransitioning={!isTransitioning} />
						{children}
					</motion.div>
				</AnimatePresence>
			</Layout>
		</div>
	);
};
