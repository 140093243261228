import classNames from 'classnames';
import PentiaLogo from 'ui/icons/pentia_logo_bookmark.svg';
import KpLogo from 'ui/icons/klausenpartners_logo_tagline.svg';
import styles from './Logo.module.scss';
import { Icon } from 'ui/components/1-atoms/Media/Icon/Icon';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';
import { GLOBAL_THEMES, GLOBAL_THEME_DEFAULT } from '_constants';

export interface LogoProps {
	className?: string;
	heading?: string;
}

export const Logo: React.FC<LogoProps> = ({ className, heading = GLOBAL_THEME_DEFAULT }) => (
	<Link className={classNames(styles.Logo, className)} href="/">
		<Heading headingLevel="h6" className={styles.Logo_heading}>
			{GLOBAL_THEMES.find((theme) => theme.slug === heading).name}
		</Heading>
		<Icon className={styles.Logo_asset} size="auto">
			{heading === 'kogp' && <KpLogo />}
			{(heading === 'pentia-dk' || heading === 'pentia-se') && <PentiaLogo />}
		</Icon>
	</Link>
);
